import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src311988156/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert, Space, IconEdit } from '@madup-inc/mad-design';
import { Playground, Props } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "alert"
    }}>{`Alert`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Alert} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Space size=\"large\" direction=\"vertical\">\n  <Alert type=\"info\" message=\"hello info\" description=\"description\" />\n  <Alert type=\"warning\" message=\"hello warning\" description=\"description\" />\n  <Alert type=\"error\" message=\"hello error\" description=\"description\" />\n  <Alert type=\"success\" message=\"hello success\" description=\"description\" />\n  <Alert\n    type=\"success\"\n    message=\"no icon success\"\n    description=\"description\"\n    showIcon={false}\n  />\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Alert,
      Space,
      IconEdit,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space size="large" direction="vertical" mdxType="Space">
    <Alert type="info" message="hello info" description="description" mdxType="Alert" />
    <Alert type="warning" message="hello warning" description="description" mdxType="Alert" />
    <Alert type="error" message="hello error" description="description" mdxType="Alert" />
    <Alert type="success" message="hello success" description="description" mdxType="Alert" />
    <Alert type="success" message="no icon success" description="description" showIcon={false} mdxType="Alert" />
  </Space>
    </Playground>
    <h2 {...{
      "id": "custom-color"
    }}>{`Custom Color`}</h2>
    <Playground __position={2} __code={'<Space size=\"large\" direction=\"vertical\">\n  <Alert\n    message=\"hello\"\n    description=\"description\"\n    bgColor=\"#fff\"\n    fontColor=\"black\"\n    borderColor=\"lightgrey\"\n    showIcon={false}\n  />\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Alert,
      Space,
      IconEdit,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space size="large" direction="vertical" mdxType="Space">
    <Alert message="hello" description="description" bgColor="#fff" fontColor="black" borderColor="lightgrey" showIcon={false} mdxType="Alert" />
  </Space>
    </Playground>
    <h2 {...{
      "id": "custom-content"
    }}>{`Custom Content`}</h2>
    <Playground __position={3} __code={'<Space size=\"large\" direction=\"vertical\">\n  <Alert\n    type=\"info\"\n    message={\n      <div>\n        <div>hello world</div>\n        <div>\n          message2 <b>12312</b>\n        </div>\n      </div>\n    }\n    description={<div style={{ color: \'grey\' }}>description...</div>}\n    icon={<IconEdit />}\n  />\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Alert,
      Space,
      IconEdit,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space size="large" direction="vertical" mdxType="Space">
    <Alert type="info" message={<div>
               <div>hello world</div>
               <div>
                 message2 <b>12312</b>
               </div>
             </div>} description={<div style={{
          color: 'grey'
        }}>
               description...
             </div>} icon={<IconEdit mdxType="IconEdit" />} mdxType="Alert" />
  </Space>
    </Playground>
    <h2 {...{
      "id": "banner-mode"
    }}>{`Banner mode`}</h2>
    <Playground __position={4} __code={'<Space size=\"large\" direction=\"vertical\">\n  <Alert type=\"info\" message=\"hello info\" description=\"description\" banner />\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Alert,
      Space,
      IconEdit,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space size="large" direction="vertical" mdxType="Space">
    <Alert type="info" message="hello info" description="description" banner mdxType="Alert" />
  </Space>
    </Playground>
    <h2 {...{
      "id": "closable"
    }}>{`Closable`}</h2>
    <Playground __position={5} __code={'<Space size=\"large\" direction=\"vertical\">\n  <Alert\n    type=\"info\"\n    message=\"hello info\"\n    description=\"description\"\n    closable\n  />\n  <Alert\n    type=\"warning\"\n    message=\"hello warning\"\n    description=\"description\"\n    closable\n  />\n  <Alert\n    type=\"error\"\n    message=\"hello error\"\n    description=\"description\"\n    closable\n  />\n  <Alert\n    type=\"success\"\n    message=\"hello success\"\n    description=\"description\"\n    closable\n  />\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Alert,
      Space,
      IconEdit,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space size="large" direction="vertical" mdxType="Space">
    <Alert type="info" message="hello info" description="description" closable mdxType="Alert" />
    <Alert type="warning" message="hello warning" description="description" closable mdxType="Alert" />
    <Alert type="error" message="hello error" description="description" closable mdxType="Alert" />
    <Alert type="success" message="hello success" description="description" closable mdxType="Alert" />
  </Space>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      